import React from 'react';
import { Tooltip } from '@packages/legacy-ui';
import { CDN_SERVICES } from '@/services/cdn/cdnService';
import { Box, Typography } from '@mui/material';
import { useCdnLabelProvider } from '@/hooks/constants/useCdnLabelProvider';
import {
  ImageCdnAkamai,
  ImageCdnAlibabacloud,
  ImageCdnAzure,
  ImageCdnByteplus,
  ImageCdnCloudflare,
  ImageCdnCloudfront,
  ImageCdnCss,
  ImageCdnGcpcdn,
  ImageCdnHuawei,
  ImageCdnHyosungitx,
  ImageCdnKt,
  ImageCdnLimelight,
  ImageCdnMzc,
  ImageCdnNimbus,
  ImageCdnOtherCDN,
  ImageCdnSk,
  ImageCdnTencent,
  ImageCdnTencentEdgeOne,
  ImageCdnUplus,
  IconHierarchyFolded,
  IconOnnet,
  IconBox
} from '@packages/cp-ui';

const CdnIconName = ({ service, name, isNameVisible = false, useOnNet = false }) => {
  const { get } = useCdnLabelProvider();
  const icon = () => {
    switch (service) {
      case CDN_SERVICES.AWS_CLOUDFRONT:
        return <ImageCdnCloudfront width={16} height={16} />;
      case CDN_SERVICES.AKAMAI:
        return <ImageCdnAkamai width={16} height={16} />;
      case CDN_SERVICES.LIMELIGHT:
        return <ImageCdnLimelight width={16} height={16} />;
      case CDN_SERVICES.AZURE_EDGIO:
        return <ImageCdnAzure width={16} height={16} />;
      case CDN_SERVICES.KT_SOLBOX:
        return <ImageCdnKt width={16} height={16} />;
      case CDN_SERVICES.ALIBABA_CLOUD_CDN:
        return <ImageCdnAlibabacloud width={16} height={16} />;
      case CDN_SERVICES.TENCENT_CLOUD_CDN:
        return <ImageCdnTencent width={16} height={16} />;
      case CDN_SERVICES.TENCENT_CLOUD_EDGEONE:
        return <ImageCdnTencentEdgeOne width={16} height={16} />;
      case CDN_SERVICES.TENCENT_CSS:
        return <ImageCdnCss width={16} height={16} />;
      case CDN_SERVICES.LG_UPLUS:
        return <ImageCdnUplus width={16} height={16} />;
      case CDN_SERVICES.GOOGLE_CLOUD_CDN:
        return <ImageCdnGcpcdn width={16} height={16} />;
      case CDN_SERVICES.CLOUDFLARE_CDN:
        return <ImageCdnCloudflare width={16} height={16} />;
      case CDN_SERVICES.HYOSUNG_ITX_CDN:
        return <ImageCdnHyosungitx width={16} height={16} />;
      case CDN_SERVICES.BYTEPLUS_CDN:
        return <ImageCdnByteplus width={16} height={16} />;
      case CDN_SERVICES.LG_NIMBUS:
        return <ImageCdnNimbus width={16} height={16} />;
      case CDN_SERVICES.HUAWEI_CLOUD_CDN:
        return <ImageCdnHuawei width={16} height={16} />;
      case CDN_SERVICES.SK_BROADBAND_CDN:
        return <ImageCdnSk width={16} height={16} />;
      case CDN_SERVICES.CLOUDPLEX_MEGAZONE_CDN:
        return <ImageCdnMzc width={16} height={16} />;
      case CDN_SERVICES.CUSTOM:
        return <ImageCdnOtherCDN width={16} height={16} />;
      default:
        return <></>;
    }
  };

  const cdnLabel = service === CDN_SERVICES.CUSTOM ? name : get(service)?.label;
  if (cdnLabel == null) return icon();

  return (
    <Box display={'inline-flex'} alignItems={'center'} gap={1}>
      {useOnNet === true && (
        <>
          <IconBox size={16} backgroundColor={'#5F62E5'}>
            <IconOnnet size={12} htmlColor={'white'} />
          </IconBox>
          <IconHierarchyFolded size={8} color={'action'} />
        </>
      )}
      <Tooltip title={cdnLabel}>
        <span>{icon()}</span>
      </Tooltip>
      {isNameVisible === true && (
        <Typography variant={'subtitle2'} component={'p'} fontWeight={'500'}>
          {cdnLabel}
        </Typography>
      )}
    </Box>
  );
};

export default CdnIconName;
