import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/* component exports*/
export { Autocomplete } from './component/autocompletes/Autocomplete';
export { ChipInput } from './component/autocompletes/ChipInput';

export { Avatar } from './component/avatars/Avatar';
export { ProjectAvatar } from './component/avatars/ProjectAvatar';
export { ExecuteDangerDialogButton } from './component/buttons/ExecuteDangerDialogButton';
export { ExecuteDialogButton } from './component/buttons/ExecuteDialogButton';
export { RejectDialogButton } from './component/buttons/RejectDialogButton';
export { UndoButton } from './component/buttons/UndoButton';
export { CancelButton } from './component/buttons/CancelButton';
export { CommandButton } from './component/buttons/CommandButton';
export { UploadButton } from './component/buttons/UploadButton';
export { InfoButton } from './component/buttons/InfoButton';
export { SignInButton } from './component/buttons/SignInButton';
export { Chip } from './component/chips/Chip';
export { Chips } from './component/chips/Chips';
export { Checkbox } from './component/checkboxes/Checkbox';

export { IconBox } from './component/boxes/IconBox';

export { Select } from './component/select/Select';
export { PagesizeSelect } from './component/select/PagesizeSelect';

export { ErrorAlert } from './component/alerts/ErrorAlert';
export { SuccessAlert } from './component/alerts/SuccessAlert';
export { WarningAlert } from './component/alerts/WarningAlert';
export { InfoAlert } from './component/alerts/InfoAlert';
export { Skeleton } from './component/skeletons/Skeleton';
export { TableBodySkeleton } from './component/skeletons/TableBodySkeleton';

export { DeleteIconButton } from './component/iconButtons/DeleteIconButton';
export { MoreIconButton } from './component/iconButtons/MoreIconButton';
export { PrevIconButton } from './component/iconButtons/PrevIconButton';

export { PopoverMenu } from './component/menus/PopoverMenu';
export { PopoverMenuItem } from './component/menus/PopoverMenuItem';

export { default as CaseRenderer } from './component/caseRenderer/CaseRenderer';
export { Dialog } from './component/containers/Dialog';
export { ErrorContainer } from './component/containers/ErrorContainer';
export { HeaderContainer } from './component/containers/HeaderContainer';
export { HeaderLabel, LightHeaderLabel } from './component/containers/HeaderLabel';
export { LoadableContainer } from './component/containers/LoadableContainer';
export { Overview } from './component/containers/Overview';
export { Tooltip } from './component/tooltips/Tooltip';

export {
  OverviewItemGroup,
  OverviewItem,
  OverviewSeparator,
  ReadOnlyHeader,
  ReadOnlyContent,
  EditableHeader,
  EditableContent
} from './component/containers/OverviewItem';
export { PropertyContainer } from './component/containers/PropertyContainer';
export { EditablePropertyContent } from './component/containers/EditablePropertyContent';
export { RequiredLabel } from './component/containers/RequiredLabel';
export { H2 } from './component/typographies/H2';
export { H4 } from './component/typographies/H4';
export { H6 } from './component/typographies/H6';
export { Typography } from './component/typographies/Typography';
export { TypographySubtitle1 } from './component/typographies/TypographySubtitle1';
export { TypographySubtitle2 } from './component/typographies/TypographySubtitle2';
export { Ellipsis } from './component/typographies/Ellipsis';

export { FilterContainer } from './component/containers/FilterContainer';
export { ValidConditionContainer } from './component/containers/ValidConditionContainer';
export { WithId } from './component/containers/WithId';
export { WithIdRow } from './component/containers/WithIdRow';
export { Header } from './component/layouts/header/Header';
export { EmptyHeader } from './component/layouts/header/EmptyHeader';
export { Footer } from './component/layouts/footer/Footer';
export { Stepper } from './component/stepper/Stepper';
export { Step } from './component/stepper/Step';
export { ListIconItem } from './component/listItems/ListIconItem';
export type { StepperController } from './component/stepper/infrastructures/StepperController';

export { RadioContainer } from './component/radios/RadioContainer';
export { RadioItem } from './component/radios/RadioItem';
export {
  Switch,
  Stack,
  Grid,
  Box,
  ButtonGroup,
  ClickAwayListener,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
  ToggleButton,
  ToggleButtonGroup,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  ListItemAvatar,
  ListSubheader,
  Divider,
  Drawer,
  Button, // todo: remove this Button
  // todo: remove this export
  InputLabel,
  IconButton,
  Popover,
  MenuItem,
  CircularProgress,
  FormControl
} from '@mzc-pdc/ui';

export {
  AppMenuDrawer,
  AppMenuDrawerBody,
  AppMenuDrawerHeader,
  AppMenuList
} from './component/drawers/AppMenuDrawer';

export {
  DetailViewDrawer,
  DetailViewDrawerBody,
  DetailViewDrawerHeader
} from './component/drawers/DetailViewDrawer';

export { InputField } from './component/inputFields/InputField';
export { NumberWithReferenceInputField } from './component/inputFields/NumberWithReferenceInputField';
export { PasswordInputField } from './component/inputFields/PasswordInputField';
export { NumberInputField } from './component/inputFields/NumberInputField';
export { TextArea } from './component/inputFields/TextArea';

export { CircularLoader } from './component/loaders/CircularLoader';
export { Link } from './component/links/Link';
export { Breadcrumbs } from './component/breadcrumbs/Breadcrumbs';
export { BreadcrumbItem } from './component/breadcrumbs/BreadcrumbItem';

export { Page } from './component/pages/Page';
export { ExtensiblePage } from './component/pages/ExtensiblePage';

export { ByteDisplay } from './component/byteDisplay/ByteDisplay';

/* theme exports*/
export {
  CpThemeProvider,
  theme,
  styled,
  PDCStyled,
  PDCAlpha as alpha,
  GlobalStyles,
  CssBaseline
} from './theme/index';
export * from '@mzc-cloudplex/icons';

export { DatePicker } from '@mui/x-date-pickers/DatePicker';
export { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
export { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
export { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export type { PDCCSSObject, CSSObject } from './theme/index';

export { default as useAnchor } from './hook/useAnchor';
export { useLocaleProvider } from './hook/useLocaleProvider';
export { useInvalidateComponent } from './hook/useInvalidateComponent';
export { useThumbnailColorProvider } from './hook/useThumbnailColorProvider';
export { useByteConverter } from './hook/useByteConverter';
