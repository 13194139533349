import SummaryInfo from '@/components/summary/SummaryInfo';
import UserInfo from '@/components/user/UserInfo';
import OriginStorageInfo from '@/components/origin/originStorage/OriginStorageInfo';
import DnsInfo from '@/components/manage/dns/DnsInfo';
import CdnInfo from '@/components/manage/cdn/CdnInfo';
import EndpointInfo from '@/components/manage/endpoint/EndpointInfo';
import RoutingPolicyInfo from '@/components/manage/routingPolicy/RoutingPolicyInfo';
import StorageInfo from '@/components/manage/storage/StorageInfo';
import NotificationInfo from '@/components/manage/notification/NotificationInfo';
import PeriodicMetricsInfo from '@/components/monitoring/cdnMetrics/PeriodicMetricsInfo';
import MonitoringInfo from '@/components/monitoring/cdnMetricsMonitoring/MonitoringInfo';
import HistoryInfo from '@/components/manage/job/HistoryInfo';
import RealtimeMonitoringInfo from '@/components/realtimeMonitoring/RealtimeMonitoringInfo';

export const INFORMATION_ID = {
  SUMMARY: 'summary',
  ENDPOINT: 'endpoint',
  USERS: 'users',
  DNS: 'dns',
  CDN: 'cdn',
  ROUTING_POLICY: 'routingPolicy',
  STORAGE: 'storage',
  HISTORY: 'history',
  NOTIFICATION: 'notification',
  ORIGIN_STORAGE: 'originStorage',
  PERIODIC_METRICS: 'periodicMetrics',
  MONITORING: 'monitoring',
  REALTIME_MONITORING: 'realtimeMonitoring'
};

export const getPanelDetailByInformationId = (informationId) => {
  switch (informationId) {
    case INFORMATION_ID.SUMMARY:
      return SummaryInfo;
    case INFORMATION_ID.ENDPOINT:
      return EndpointInfo;
    case INFORMATION_ID.USERS:
      return UserInfo;
    case INFORMATION_ID.DNS:
      return DnsInfo;
    case INFORMATION_ID.CDN:
      return CdnInfo;
    case INFORMATION_ID.ROUTING_POLICY:
      return RoutingPolicyInfo;
    case INFORMATION_ID.STORAGE:
      return StorageInfo;
    case INFORMATION_ID.HISTORY:
      return HistoryInfo;
    case INFORMATION_ID.NOTIFICATION:
      return NotificationInfo;
    case INFORMATION_ID.ORIGIN_STORAGE:
      return OriginStorageInfo;
    case INFORMATION_ID.PERIODIC_METRICS:
      return PeriodicMetricsInfo;
    case INFORMATION_ID.MONITORING:
      return MonitoringInfo;
    case INFORMATION_ID.REALTIME_MONITORING:
      return RealtimeMonitoringInfo;
    default:
      return null;
  }
};
